<template>
  <div>
    <div class="bgBox">
      <el-carousel arrow="never" indicator-position="none">
        <el-carousel-item v-for="img in bglist" :key="img.id">
          <img class="img" :src="img.url" alt="" />
        </el-carousel-item>
      </el-carousel>
      <div class="bgTitle">
        <div>智慧一体化云平台</div>
        <div>一个提供高效的管理解决方案平台</div>
        <router-link to="/aboutwe">联系我们</router-link>
      </div>
    </div>
    <div class="container">
      <div class="mainTop">
        <div class="mainauto">
          <div class="topTitle">
            <p class="maintitle">基于产品的个性化开发服务</p>
            <p class="subtitle">
              基于现有的，以及对该业务领域的深刻理解，提供专业的个性化开发服务，全方
              <br />位，满足您的业务需求。二次开发也是平衡共性需求和个性需求的最佳方案。
            </p>
          </div>
          <div class="topMain">
            <div class="numBox">
              <span class="topnum">166<sup>+</sup></span
              >人<br />技术专家
            </div>
            <div class="numBox">
              <span class="topnum">40<sup>+</sup></span
              >种<br />开源SDK
            </div>
            <div class="numBox">
              <span class="topnum">20<sup>+</sup></span
              >家<br />行业覆盖
            </div>
            <div class="numBox">
              <span class="topnum">600<sup>+</sup></span
              >家<br />正式付款客户
            </div>
          </div>
        </div>
      </div>
      <div class="mainCentre">
        <div class="mainauto">
          <el-row>
            <el-col :span="9"
              ><div class="grid-content bg-purple">
                <div class="centre_title">软硬件一体提供专业的服务</div>
                <div class="centre_main">基于产品的个性化开发服务</div>
                <div class="centremain">
                  <img
                    src="@/assets/softwarecustomization/main_icon1.png"
                    alt=""
                  />
                  <div>
                    <div class="centertitle">按需定制</div>
                    <div class="centre_main">
                      当通用性产品无法满足用户的个性化需求时，二次开发是平衡通用软件与定制软件，共性需求与个性需求的最佳方案。
                    </div>
                  </div>
                </div>
                <div class="centremain">
                  <img
                    src="@/assets/softwarecustomization/main_icon2.png"
                    alt=""
                  />
                  <div>
                    <div class="centertitle">快速响应</div>
                    <div class="centre_main">
                      基于平台和产品的定制开发，能快速响应客户个性化需求，最大限度的缩短开发周期,有效降低开发成本,提高客户满意度。
                    </div>
                  </div>
                </div>
                <div class="centremain">
                  <img
                    src="@/assets/softwarecustomization/main_icon3.png"
                    alt=""
                  />
                  <div>
                    <div class="centertitle">稳定可靠</div>
                    <div class="centre_main">
                      专注于组织信息化建设，在个性化开发上积累了丰富的开发经验和团队建设经验,拥有一套成熟的定制解决方案。
                    </div>
                  </div>
                </div>
              </div></el-col
            >
            <el-col :span="15"
              ><div class="grid-content bg-purple gridimg">
                <img
                  class="imgright"
                  src="@/assets/softwarecustomization/main_right.png"
                  alt=""
                /></div
            ></el-col>
          </el-row>
        </div>
      </div>
      <div class="mainBottom">
        <div class="bottomTitle">专业定制化流程</div>
        <div class="bottom_subtitle">流程简单清晰，快速响应需求</div>
        <div class="orderBox">
          <el-tabs v-model="activeName">
            <el-tab-pane label="初期流程" name="first">
              <div class="orderMain">
                <ul>
                  <li>
                    <img
                      src="@/assets/softwarecustomization/bottom_icon1.png"
                      alt=""
                    />
                    <div>需求沟通</div>
                  </li>
                  <li>
                    <img
                      src="@/assets/softwarecustomization/bottom_path.png"
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      src="@/assets/softwarecustomization/bottom_icon2.png"
                      alt=""
                    />
                    <div>方案确立</div>
                  </li>
                  <li>
                    <img
                      src="@/assets/softwarecustomization/bottom_path.png"
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      src="@/assets/softwarecustomization/bottom_icon3.png"
                      alt=""
                    />
                    <div>项目立项</div>
                  </li>
                  <li>
                    <img
                      src="@/assets/softwarecustomization/bottom_path.png"
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      src="@/assets/softwarecustomization/bottom_icon4.png"
                      alt=""
                    />
                    <div>项目调研</div>
                  </li>
                </ul>
              </div></el-tab-pane
            >
            <el-tab-pane label="终期流程" name="second"
              ><div class="orderMain">
                <ul>
                  <li>
                    <img
                      src="@/assets/softwarecustomization/bottom_icon5.png"
                      alt=""
                    />
                    <div>软件开发</div>
                  </li>
                  <li>
                    <img
                      src="@/assets/softwarecustomization/bottom_path.png"
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      src="@/assets/softwarecustomization/bottom_icon6.png"
                      alt=""
                    />
                    <div>测试试用</div>
                  </li>
                  <li>
                    <img
                      src="@/assets/softwarecustomization/bottom_path.png"
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      src="@/assets/softwarecustomization/bottom_icon7.png"
                      alt=""
                    />
                    <div>正式上线</div>
                  </li>
                  <li>
                    <img
                      src="@/assets/softwarecustomization/bottom_path.png"
                      alt=""
                    />
                  </li>
                  <li>
                    <img
                      src="@/assets/softwarecustomization/bottom_icon8.png"
                      alt=""
                    />
                    <div>系统维护</div>
                  </li>
                </ul>
              </div></el-tab-pane
            >
          </el-tabs>
        </div>

        <div class="button">
          <div><router-link to="/aboutwe">联系我们</router-link></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "software-customization",
  data() {
    return {
      bglist: [
        { id: 0, url: require("@/assets/softwarecustomization/bg1.png") },
      ],
      activeName: "first",
    };
  },
};
</script>

<style scoped>
.orderBox >>> .el-tabs__nav-scroll {
  display: flex;
    display: -webkit-flex;
  justify-content: center;
  margin: 56px 0;
}
.orderBox >>> .el-tabs__nav-wrap::after,
.orderBox >>> .el-tabs__active-bar {
  display: none;
}
.orderBox >>> .el-tabs__item.is-active {
  color: #404040;
}
.orderBox >>> .el-tabs__item {
  color: #999;
  font-size: 20px;
  font-weight: bold;
  height: 20px;
  line-height: 20px;
  padding: 0 60px;
}
.orderBox >>> .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
  border-right: 1px solid #999;
}
.bgBox {
  width: 100%;
  position: relative;
}
.bgBox >>> .el-carousel__container {
  height: 700px;
}
.bgTitle {
  min-width: 700px;
  position: absolute;
  color: #fff;
  left: 100px;
  bottom: 170px;
  z-index: 9;
  line-height: 50px;
}
.bgTitle > div:nth-child(1) {
  font-size: 48px;
}
.bgTitle > div:nth-child(2) {
  font-size: 14px;
}
.bgTitle a {
  display: block;
  width: 110px;
  height: 40px;
  font-size: 16px;
  border-radius: 6px;
  border: 1px solid #fff;
  text-align: center;
  line-height: 36px;
}
.img {
  width: 100%;
  height: 100%;
}
.container {
  width: 100%;
}
.mainTop {
  width: 100%;
  background-color: #fafbfd;
}
.mainauto {
  width: 1200px;
  margin: 0 auto;
}
.mainTop .mainauto {
  padding: 56px 0 80px;
}
.maintitle {
  font-size: 38px;
  color: #404040;
  position: relative;
  text-align: center;
}
.maintitle::after {
  content: "";
  width: 44px;
  height: 4px;

  background-color: #5f9bf1;
  position: absolute;
  left: 50%;
  bottom: -4px;
  transform: translateX(-50%);
}
.subtitle {
  padding: 40px 0;
  font-size: 18px;
  color: #666;
  text-align: center;
}
.numBox {
  font-size: 20px;
  color: #333;
}
.topnum {
  font-size: 64px;
  color: #5f9bf1;
}
.topMain {
  display: flex;
    display: -webkit-flex;
  justify-content: space-between;
}
.centre_title {
  font-size: 24px;
  color: #333;
  padding: 40px 0 32px;
}
.centre_main {
  font-size: 12px;
  color: #666;
  margin-bottom: 64px;
}

.centremain {
  display: flex;
    display: -webkit-flex;
}
.centremain img {
  width: 62px;
  height: 50px;
  margin-right: 20px;
}
.centertitle {
  font-size: 14px;
  color: #404040;
  font-weight: bold;
  margin-bottom: 28px;
}
.gridimg {
  text-align: right;
  padding-top: 206px;
}
.imgright {
  width: 430px;
  height: 360px;
}
.mainBottom {
  margin-top: 124px;
  width: 100%;
  background: url("../assets/softwarecustomization/bottom_bg.png") no-repeat
    bottom;
  background-size: 100% 100%;
  text-align: center;
}
.bottomTitle {
  font-size: 32px;
  color: #404040;
  padding: 32px 0;
}
.bottom_subtitle {
  font-size: 18px;
  color: #666;
}
.orderName {
  padding: 56px 0;
  font-size: 20px;
  color: #999;
  font-weight: bold;
  cursor: pointer;
}
.orderName span {
  border-right: 1px solid #999;
  padding: 0 70px;
}
.orderName span:last-child {
  border: none;
}
.orderName .active {
  color: #404040;
}
.orderBox {
  overflow: hidden;
}
.orderMain {
  overflow: hidden;
}
.orderMain ul {
  display: flex;
    display: -webkit-flex;
  flex-wrap: nowrap;
  width: 100%;
}
.orderMain li {
  width: calc(100%/7);
  height: 216px;
  text-align: center;
  padding: 56px 0 80px;
  line-height: 24px;
  position: relative;
}
.orderMain li:nth-child(odd) img {
  width: 50px;
}
.orderMain li:nth-child(even) img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
}
.orderMain li:first-child {
  background: url("../assets/softwarecustomization/bottom_iconbg.png") no-repeat;
  background-size: 100% 100%;
}
.button {
  width: 100%;
  height: 260px;
  background: url("../assets/index/bottom_bg.png") no-repeat bottom;
  background-size: 100% 60%;
  position: relative;
}
.button div {
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
  background: url("../assets/softwarecustomization/button_bg.png") no-repeat;
  background-size: 100% 100%;

  color: #fff;
}
.button div a {
  display: block;
  width: 200px;
  height: 100px;
  padding: 30px 60px;
}
</style>